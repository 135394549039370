<template>
  <section class="w-full md:w-1/2 p-5 mx-auto">
    <div id="header" class="flex items-center justify-center mb-4 mr-4">
      <div class="flex align-baseline absolute left-0 top-0">
        <router-link to="/" class="form-button form-button--mini form-button--back form-button--outline uppercase">
          Back
        </router-link>
        <ActionButton class="form-button form-button--mini form-button--outline uppercase pa-1 ml-2">
          <download-csv :data="results" :name="`Result List - ${new Date().toLocaleString()}.csv`" :fields="columns">
            Download
          </download-csv>
        </ActionButton>
      </div>
      <h1 class="title">Result List</h1>
    </div>
    <div class="w-full overflow-auto text-center">
      <Loader v-if="loading" />
      <table v-else class="border border-green-800 text-sm mx-auto">
        <thead class="whitespace-nowrap">
          <tr>
            <th class="p-2 border-b">Date & Time</th>
            <th class="p-2 border-b">Result</th>
            <th class="p-2 border-b">LOT No.</th>
            <th class="p-2 border-b">Sterilizer</th>
            <th class="p-2 border-b">Cycle Name</th>
            <th class="p-2 border-b">Operator</th>
            <th class="p-2 border-b">Sent to Email(s)</th>
            <th class="p-2 border-b">Indicator Photo</th>
            <th class="p-2 border-b">Resend</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="result in results" :key="result.id" class="whitespace-nowrap">
            <td class="p-2">{{ getDateTimeString(result.timestamp) }}</td>
            <td class="p-2">{{ result.result }}</td>
            <td class="p-2">{{ result.lot }}</td>
            <td class="p-2">{{ result.selectedSterilizer }}</td>
            <td class="p-2">{{ result.selectedCycle }}</td>
            <td class="p-2">{{ result.userName }}</td>
            <td class="p-2">
              {{ result.clinicData.email }}<span v-if="result.selectedEmail">, {{ result.selectedEmail }}</span>
            </td>
            <td class="p-2">
              <a v-if="result.imageName" :id="`image_${index}`" href="#" @click="showImage(result.imageName)">Image</a>
            </td>
            <td class="p-2 cursor-pointer" @click="emailResult(result)">
              <v-icon small>mdi-send</v-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import { ActionButton, Loader } from "@/components";
import { DateTime } from "luxon";
import { mapGetters } from "vuex";

export default {
  name: "Results",
  data: () => ({
    imageUrl: "",
    loading: false,
    operators: [],
    results: [],
    columns: ["lot", "timeString", "selectedCycle", "timestamp", "fullName", "result", "selectedSterilizer"]
  }),
  components: { ActionButton, Loader },
  async created() {
    this.loading = true;
    await this.$store.dispatch("auth/fetchCurrentUserData", this.currentUser.uid);

    this.operators = await this.$store.dispatch("clinic/fetchUsers", this.currentUser.clinicUid);
    for (let operator of this.operators.docs) {
      const result = await this.$store.dispatch("results/fetchResults", operator.data().uid);
      this.results.push(...result);
    }
    this.results = this.orderByDate(this.results);
    this.loading = false;
  },

  methods: {
    emailResult(result) {
      this.$store
        .dispatch("results/emailResult", result)
        .then(() => this.$toast.success("Result sent to email"))
        .catch(() => this.$toast.error("Error sending result to email"));
    },

    getDateTimeString(timestamp) {
      return new DateTime.fromMillis(timestamp).toFormat("dd/LL/yyyy HH:mm");
    },

    showImage(imageName) {
      this.$store.dispatch("results/getImageUrl", imageName).then((url) => window.open(url, "_blank"));
    },

    orderByDate(array) {
      return array.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" })
  }
};
</script>
