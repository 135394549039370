<template>
  <app-layout>
    <template #title>Add Operator</template>
    <template #content>
      <form class="flex flex-col space-y-4" @submit.prevent @submit.prevent.once="addOperator">
        <v-text-field
          class="centered-input"
          v-model="fullName"
          :rules="[rules.required, rules.minLength]"
          label="Full Name"
          required
        />
        <v-text-field
          class="centered-input"
          v-model="email"
          :rules="[rules.required, rules.minLength, rules.email]"
          label="Email"
          type="email"
          required
        />
        <div class="mt-4 pb-20 flex justify-center items-center">
          <base-button type="submit" ring>Submit</base-button>
        </div>
      </form>
    </template>
  </app-layout>
</template>

<script>
import BaseButton from "@/components/buttons/BaseButton";
import AppLayout from "@/layouts/AppLayout.vue";
import { mapGetters } from "vuex";

export default {
  components: { AppLayout, BaseButton },
  data: () => ({
    fullName: "",
    email: ""
  }),
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser" }),
    rules: () => ({
      required: (value) => !!value || "Required.",
      minLength: (value) => value.length >= 3 || "Field must be longer than 3 characters",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    })
  },
  methods: {
    addOperator() {
      this.$store
        .dispatch("auth/createUser", {
          fullName: this.fullName,
          email: this.email
        })
        .then(() => {
          this.$toast.success("User is being created.");
          this.$router.push("/users");
        })
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "ADD_OPERATOR",
            clinicUid: this.currentUser.clinicUid,
            notes: `Operator ${this.fullName} (${this.email}) has been added.`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName
          });
        });
    }
  }
};
</script>
