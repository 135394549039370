<template>
  <app-layout>
    <template #title>Users</template>
    <template #content>
      <nav-button v-if="currentUser.clinicUid" to="/users/add-operator" outline>Add Operator</nav-button>
      <nav-button v-else to="/clinic" outline>Create Clinic</nav-button>
      <div class="w-full overflow-auto">
        <table class="border border-green-800 text-sm mx-auto">
          <thead class="whitespace-nowrap">
            <tr>
              <th class="p-2 border-b"></th>
              <th class="p-2 border-b">UserName</th>
              <th class="p-2 border-b">Full Name</th>
              <th class="p-2 border-b">Email</th>
              <th class="p-2 border-b">Status</th>
              <th class="p-2 border-b">Admin</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, index) in usersList" :key="index" class="whitespace-nowrap">
              <td class="p-2">
                <a href="#" v-if="user.uid !== currentUser.uid" @click="handleUserEdit(user.uid)">Edit</a>
              </td>
              <td class="p-2">{{ user.userName }}</td>
              <td class="p-2">{{ user.fullName }}</td>
              <td class="p-2">{{ user.email }}</td>
              <td class="p-2 text-center">{{ user.isDisabled ? "Disabled" : "Active " }}</td>
              <td class="p-2 text-center">{{ isAdmin(user) ? "✔" : "" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </app-layout>
</template>

<script>
import NavButton from "@/components/buttons/NavButton.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import { mapGetters } from "vuex";

export default {
  name: "UsersPage",
  components: { AppLayout, NavButton },
  data: () => ({
    usersList: []
  }),

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      admins: "clinic/admins"
    })
  },

  created() {
    this.$store
      .dispatch("auth/fetchCurrentUserData", this.currentUser.uid)
      .then(() => {
        this.$store.dispatch("clinic/fetchClinicData", this.currentUser.clinicUid);
      })
      .then(() => {
        this.$store.dispatch("clinic/fetchUsers", this.currentUser.clinicUid).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const user = doc.data();

            // todo: filter in clinic.js module .where("isDeleted", "!=", "true");
            if (user.isDeleted) {
              return;
            }

            this.usersList.push(user);
          });
        });
      })
      .catch((error) => console.log(error));
  },

  watch: {
    usersList: {
      handler() {
        console.log("Updating users list", this.usersList);
      }
    }
  },

  methods: {
    handleUserEdit(userId) {
      this.$router.push({ name: "ManageUser", params: { id: userId } });
    },
    isAdmin(user) {
      return this.admins.includes(user.uid);
    }
  }
};
</script>
