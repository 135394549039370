<template>
  <transition name="fade">
    <div>
      <app-layout v-if="fullName">
        <template #title>
          {{ fullName }}
          <br />
          Account
        </template>
        <template #content>
          <div class="mt-20 mx-6">
            <nav-button :to="{ name: 'UserEditEmails' }" outline class="mb-9 normal-case">Email Addresses</nav-button>
            <action-button class="mb-9" @action-button-click="handleChangeUserPassword()" outline>
              Change Password
            </action-button>
            <action-button class="mb-9" @action-button-click="sendPasswordResetEmail()" outline>
              Reset Password
            </action-button>
            <action-button class="mb-9" @action-button-click="handleMakeAdmin()" outline>
              {{ isAdmin ? "Remove Admin" : "Make Admin" }}
            </action-button>
            <action-button v-if="statusIsEditabe" class="mb-9" @action-button-click="handleUpdateStatus()" outline>
              {{ isDisabled ? "Enable" : "Disable" }}
            </action-button>
            <action-button class="mb-9" @action-button-click="handleDeleteUserFunction()" outline>Delete</action-button>
          </div>
        </template>
      </app-layout>
      <modal ref="userStatusModal" v-if="statusIsEditabe">
        <div class="flex flex-column m-6">
          <p>{{ isDisabled ? "Do you want to enable this account?" : "Disable this user account?" }}</p>
          <div class="flex flex-row justify-between">
            <base-button @click="$refs.userStatusModal.closeModal()" primary>NO</base-button>
            <base-button @click="updateUserStatus()" outline>Yes</base-button>
          </div>
        </div>
      </modal>
      <modal ref="makeAdminModal">
        <div class="flex flex-column m-6">
          <div v-if="!isAdmin">
            <p>Give this user account administrator permissions?</p>
            <p>
              Note: Admin users can add new users, disable, delete and change other user accounts, add/change
              sterilizerinformation, etc.
            </p>
          </div>
          <div v-else>
            <p>Remove administrator permissions from this user account?</p>
          </div>
          <div class="flex flex-row justify-between">
            <base-button @click="$refs.makeAdminModal.closeModal()" primary>NO</base-button>
            <base-button @click="changeUserRank()" outline>Yes</base-button>
          </div>
        </div>
      </modal>
      <modal ref="deleteUserModal">
        <div class="flex flex-column m-6">
          <p>Delete this user account?</p>
          <p>
            Note: Admin users can add new users, disable, delete and change other user accounts, add/change sterilizer
            information, etc.
          </p>
          <div class="flex flex-row justify-between">
            <base-button @click="$refs.deleteUserModal.closeModal()" primary>NO</base-button>
            <base-button @click="deleteUser()" outline>Yes</base-button>
          </div>
        </div>
      </modal>
      <modal ref="changePassword">
        <div class="flex flex-column m-4">
          <p>Type new password:</p>
          <form
            class="flex flex-col justify-center space-y-10 mx-auto mt-8 w-72"
            @submit.prevent="validateBeforeSubmit"
          >
            <form-input
              class="w-full"
              name="password"
              type="password"
              placeholder="Password"
              v-validate="'required|min:6'"
              v-model="newPassword"
            />
            <span v-show="errors.has('password')" class="text-xs text-red-500">{{ errors.first("password") }}</span>
          </form>
          <div class="flex flex-row justify-between space-x-4 mt-6">
            <base-button @click="$refs.changePassword.closeModal()" primary>Cancel</base-button>
            <base-button type="submit" @click="changeUserPassword()" outline>Save</base-button>
          </div>
        </div>
      </modal>
    </div>
  </transition>
</template>

<script>
import { ActionButton, BaseButton, FormInput, Modal, NavButton } from "@/components";
import AppLayout from "@/layouts/AppLayout";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: { AppLayout, ActionButton, BaseButton, FormInput, Modal, NavButton },
  data: () => ({
    fullName: "",
    newPassword: ""
  }),
  methods: {
    handleUpdateStatus() {
      this.$refs.userStatusModal.openModal();
    },

    updateUserStatus() {
      const payload = {
        uid: this.$route.params.id,
        isDisabled: !this.isDisabled
      };

      this.$store.dispatch("clinic/disableUser", payload).then(() => this.$refs.userStatusModal.closeModal());
    },

    handleMakeAdmin() {
      this.$refs.makeAdminModal.openModal();
    },

    changeUserRank() {
      this.$store
        .dispatch("clinic/changeUserRank", this.$route.params.id)
        .then(() => this.$refs.makeAdminModal.closeModal())
        .then(() => this.$toast.success(`The user has role changed to ${this.isAdmin ? "user" : "admin"}.`))
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "CHANGE_USER_RANK",
            clinicUid: this.currentUser.clinicUid,
            notes: `${this.targetUser.fullName} has role changed to ${this.isAdmin ? "user" : "admin"}`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName,
          });
        });
    },

    handleDeleteUserFunction() {
      this.$refs.deleteUserModal.openModal();
    },

    deleteUser() {
      this.$store
        .dispatch("clinic/deleteUser", this.$route.params.id)
        .then(() => {
          this.$store.dispatch("audit/addRecord", {
            actionName: "DELETE_OPERATOR",
            clinicUid: this.currentUser.clinicUid,
            notes: `Operator ${this.targetUser.fullName} has been deleted`,
            uid: this.currentUser.uid,
            userEmail: this.currentUser.email,
            userName: this.currentUser.userName,
          });
        })
        .then(() => {
          console.log(`User ${this.$route.params.id} was deleted`);
          this.$refs.deleteUserModal.closeModal();
          this.pushBack();
        });
    },

    handleChangeUserPassword() {
      this.$refs.changePassword.openModal();
    },

    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.changeUserPassword();
          return;
        }
        console.log("The form is not submitted. Correct the errors");
      });
    },

    changeUserPassword() {
      this.$store
        .dispatch("clinic/changeUserPassword", { uid: this.$route.params.id, newPassword: this.newPassword })
        .then(() => this.$refs.changePassword.closeModal());
    },

    sendPasswordResetEmail() {
      this.$store.dispatch("clinic/sendPasswordResetEmail", this.targetUser.email);
    },

    pushBack() {
      const fullPath = this.$route.fullPath.split("/");
      const backPath = fullPath.slice(0, fullPath.length - 1).join("/");
      this.$router.push(backPath);
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      targetUser: "userData/targetUser",
      admins: "clinic/admins",
      // todo: refactor to target user
      isDisabled: "userData/isDisabled"
    }),
    isAdmin() {
      return this.admins.includes(this.targetUser.uid);
    },
    statusIsEditabe() {
      return this.currentUser.uid !== this.$route.params.id;
    }
  },
  created() {
    this.$store
      .dispatch("userData/fetchUserData", this.$route.params.id)
      .then(() => (this.fullName = this.targetUser.fullName));
  }
};
</script>
